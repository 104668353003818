.tb_table_action_input {
	position: relative;
	height: 38px;
	margin-right: 15px;
	flex-grow: 1;
}
.tb_table_container {
	padding: 0 0.75rem;
	margin-top: 20px;
}
.tb_table_action_input input {
	width: 100%;
	height: 40px;
	background: #ffffff;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 12px;
	padding: 0 0.75rem 0 48px;
}

.tb_table_action_input input:focus-visible {
	outline: none;
}

.tb_table_action_input img {
	position: absolute;
	left: 16px;
	top: 9px;
}

.tb_table_action_select select {
	background-color: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 12px;
	display: inline-block;
	font: inherit;
	line-height: 22px;
	padding: 8px 2em 8px 16px;
	color: var(--weak);

	margin: 0 15px 0 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
.tb_table_action_select select {
	background-image: url('../assets/icons/ic-cheveron.svg');
	background-position: calc(100% - 14px) calc(0.85em + 0px), calc(100% - 15px) calc(0.85em + 2px), calc(100% - 2.5em) 0.5em;
	background-repeat: no-repeat;
}
.tb_table_action_select select:focus {
	background-image: url('../assets/icons/ic-cheveron.svg');
	background-position: calc(100% - 12px) 0.85em, calc(100% - 19.66px) 0.85em, calc(100% - 2.5em) 0.5em;
	background-repeat: no-repeat;
	outline: 0;
}

.tb_table_action_btn {
	width: 100px;
	height: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 12px;
	font: normal normal medium 14px/16px Inter;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tb_table_action_btn svg {
	width: 12px;
	height: 12px;
	margin-right: 0.25rem;
}
.tb_loader {
	position: absolute;
	top: 50%;
	left: 60%;
	transform: translate(-50%, -50%);
	z-index: 1000;
}
.tb_loader > div {
	width: 48px;
	height: 48px;
}

.table-header {
	width: 100%;
	height: 100%;
	padding: 0;
	margin-bottom: 16px;

	font: 600 13px/16px Inter;
	color: var(--weak);
}

.table_action {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	overflow-x: auto;
	margin: 16px 0 16px auto;
	padding: 0 0.75rem;
}
.table_action > div,
.table_action > button {
	flex: 0 0 auto;
}

.table_action_input {
	position: relative;
	height: 40px;
	margin-right: 15px;
	flex-grow: 1;
}
.table_action_input input {
	width: 100%;
	height: 40px;
	background: #ffffff;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 12px;
	padding: 0 0.75rem 0 48px;
}
.table_action_input input:focus-visible {
	outline: none;
}
.table_action_input img {
	position: absolute;
	left: 16px;
	top: 9px;
}

.table_action_select select {
	width: 224px;
	background-color: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 12px;
	display: inline-block;
	font: inherit;
	line-height: 22px;
	padding: 8px 2em 8px 16px;
	color: var(--weak);

	margin: 0 15px 0 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
.table_action_select select {
	background-image: url('../assets/icons/ic-cheveron.svg');
	background-position: calc(100% - 14px) calc(0.85em + 0px), calc(100% - 15px) calc(0.85em + 2px), calc(100% - 2.5em) 0.5em;
	background-repeat: no-repeat;
}
.table_action_select select:focus {
	background-image: url('../assets/icons/ic-cheveron.svg');
	background-position: calc(100% - 12px) 0.85em, calc(100% - 19.66px) 0.85em, calc(100% - 2.5em) 0.5em;
	background-repeat: no-repeat;
	outline: 0;
}
.table_action_select.m-0 select {
	margin: 0;
}

.table_action_btn {
	width: 100px;
	height: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid var(--bgContentSecondary);
	border-radius: 12px;
	font: normal 14px/16px Inter;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}
.table_action_btn svg {
	width: 12px;
	height: 12px;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
}
.table_action_btn.large {
	width: auto;
	height: 48px;
	padding: 8px 18px;
	font: 400 16px/16px Inter;
}
.table_action_btn.small {
	width: auto;
	height: 38px;
	padding: 8px 18px;
	font: 400 16px/16px Inter;
}

.table_selected_row {
	position: relative;
	background-color: #f4f4f4;
	padding: 14px 12px 12px 12px;
	font: 600 13px/16px Inter;
	color: var(--weak);
}
.table_selected_row .preview {
	position: relative;
	width: 120px;
	height: 80px;
	padding: 8px 10px;
	background-color: #d9d9d9;
	border-radius: 8px;
}
.table_selected_row .preview.action {
	cursor: pointer;
}
.table_selected_row .preview.xl {
	height: 90px;
}
.table_selected_row .preview > img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.table_selected_header {
	border-bottom: 1px solid var(--bgContentSecondary);
	padding-bottom: 12px;
}
.table_header_column_title {
	font: 600 13px/16px Inter;
	color: #6f767e;
}
.table_row {
	width: 100%;
	height: 90px;
	/* display: flex; */
	/* align-items: center; */

	background-color: transparent;
	box-shadow: none;
	transition: box-shadow 150ms;
	border: 1px solid transparent;
}
.table_row:not(:last-of-type) {
	border: 1px solid transparent;
}
.table_row:last-of-type {
	margin-bottom: 1rem;
}
.table_row:hover {
	border-radius: 10px;
}
.table_row > td {
	height: inherit;
	word-break: break-word;
	font: 600 15px/24px Inter;
	color: #1a1d1f;
}

.table-row-setting {
	position: relative;
	height: 64px;
	width: 100%;
	display: flex;
	padding: 12px;
	border-radius: 8px;
	align-items: center;
	transition: box-shadow 150ms;
	background-color: transparent;
}
.table-row-setting:hover {
	background-color: var(--bgDisable);
}
.table-row-setting.icons {
	height: 92px;
}

.table-sign-list-container {
	width: 100%;
	padding: 0px 20px;
}
.table-sign-list-header {
	font: normal normal 600 13px Inter;
	color: var(--weak, #6f767e);
}
.table-sign-list-item {
	font: normal normal 600 15px Inter;
	color: var(--textTable, #1a1d1f);
}

.table-sign-list-header,
.table-sign-list-item {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ddd;
	padding: 10px;
	background: white;
	min-width: 100%;
}

.table-sign-list-header .column,
.table-sign-list-item .column {
	flex: 1;
	padding: 10px;
	text-align: left;
}

.table-sign-list-header .column.ck,
.table-sign-list-item .column.ck {
	flex: none;
	width: 48px;
	padding: 10px;
	display: flex;
	justify-content: center;
}

.table-sign-list-item .image-box img {
	width: 90px;
	height: auto;
}
